body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  margin-bottom: 1rem;
}

/* company theme specific style (depends on header and footer heights) */
main {
  /* min-height = view_height - header_height - (footer_height + footer_margin_top) */
  min-height: calc(100vh - 76px - (197px + 3rem));
}

footer {
  margin-top: 8rem;
}

/* bootstrap overrides */

.form-check > input[type="checkbox"] {
  width: 1.25rem;
  height: 1.25rem;
}

.form-check > label {
  margin: 0.125rem 0 0 1rem;
}
